import React, { Component } from "react";
import { connect } from 'react-redux';
import Select from 'react-select';

import _ from 'i18n';
import { setCurrentEmailCampaignData, setTextModelsTargetApp } from "actions";
import { EVENT_RECIPIENT_TYPES, MEMBER_RECIPIENT_TYPES, SURVEY_RECIPIENT_TYPES } from "Common";
import { PAYMENT_RECIPIENT_TYPES } from "../../../config/Common";
import { S3_FOLDER_URL_PROD } from 'Config';

@connect((store) => {
  return {
    recipientType: store.currentCampaign.email.recipientType,
    disableRecipientType: store.currentCampaign.email.disableRecipientType,
    currentClient: store.auth.currentClient,
  }
})
export default class RecipientType extends Component {

  handleRecipientTypeChange = ({ value }) => {
    this.props.dispatch(setCurrentEmailCampaignData({ recipientType: value, eventFilters: [], allowedGroups: [], deniedGroups: [] }));
  }

  getOptions = (fields) => {
    return fields.map(type => { return { label: _(type).toUpperCase(), value: type + "" } })
  }

  render() {
    let { recipientType, disableRecipientType, currentClient } = this.props;
    const member_recipient_types = MEMBER_RECIPIENT_TYPES;
    if (currentClient.id === 1256){
      if (!member_recipient_types.includes("OECCBB_MEMBERS")) {
        member_recipient_types.push('OECCBB_MEMBERS');
      }
    } else {
      if (member_recipient_types.includes("OECCBB_MEMBERS")) {
        member_recipient_types.pop();
      }
    }

    const typesOptions = [
      {
        label: _("community"),
        options: this.getOptions(member_recipient_types),
        icon: 'directory'
      },
      {
        label: "event",
        options: this.getOptions(EVENT_RECIPIENT_TYPES),
        icon: 'event'
      },
      {
        label: "payment",
        options: this.getOptions(PAYMENT_RECIPIENT_TYPES),
        icon: 'payment'
      },
      {
        label: "survey",
        options: this.getOptions(SURVEY_RECIPIENT_TYPES),
        icon: 'survey'
      },
    ];

    let options = typesOptions.reduce((acc, o) => acc.concat(o.options), []);
    const value = options.filter(({ value }) => value == recipientType);
    return <Select
      value={value}
      options={typesOptions}
      formatGroupLabel={formatGroupLabel}
      onChange={this.handleRecipientTypeChange}
      className={`ttp-select-container ${value ? "filled-box" : "empty-box"}`}
      classNamePrefix="ttp-select"
      isDisabled={disableRecipientType}
      styles={{
        option: (provided) => ({
          ...provided,
          padding: '8px 30px',
          fontSize: 13
        })
      }}
    />
  }
}

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const iconStyle = {
  width: '20px',
  marginRight: '6px',
}

const labelStyle = {
  display: 'flex',
  alignItems: 'center',
  fontSize: 14,
  color: '#505050'
}

const formatGroupLabel = data => {
  return <div style={groupStyles}>
    <div style={labelStyle}>
      <img style={iconStyle} src={`${S3_FOLDER_URL_PROD}/apps/${data.icon}.png`} />
      <span>{data.label}</span>
    </div>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
};
