// Campaign reducer
const initialState = {
  fetching: false,
  fetched: false,
  updated: false,
  updating: false,
  sent: false,
  sending: false,
  items: [],
  newsLetterItems: [],
  error: null,
  nbResult: 0,
  emailPageSize: 10,
  smsPageSize: 10,
  emailPaginationPage: 1,
  smsPaginationPage: 1,
  filter: {
    status: "",
    language: "",
    subject: "",
    type: "",
    smsMessage: "",
    campaignName: "",
    recipientType: "",
    dateRange: {
      startDate: null,
      endDate: null,
    },
  },
  formulas: [],
  statsFetched: false,
  statsFetching: false,
  recipientCriteria: [],
};

export const campaignsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_CAMPAIGNS_PENDING": {
      return { ...state, fetched: false, fetching: true };
      break;
    }
    case "FETCH_MEMBRSHIP_CLIENTS_STATS_FULFILLED": {
      const { data } = action.payload.data;
      return {
        ...state,
        statsFetching: false,
        statsFetched: true,
        error: null,
        formulas: data,
      };
      break;
    }
    case "FETCH_MEMBRSHIP_CLIENTS_STATS_PENDING": {
      return { ...state, fetched: false, statsFetching: true };
      break;
    }
    case "FETCH_MEMBRSHIP_CLIENTS_STATS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        statsFetching: false,
        error,
      };
      break;
    }
    case "FETCH_CAMPAIGNS_FULFILLED": {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult: nbResult,
      };
      break;
    }
    case "FETCH_CAMPAIGNS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error,
      };
      break;
    }
    case "REMOVE_CAMPAIGN_PENDING": {
      return { ...state, updated: false, updating: true };
      break;
    }
    case "REMOVE_CAMPAIGN_FULFILLED": {
      const { payload } = action;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        updated: true,
        updating: false,
        items: state.items.filter((item) => item.id !== payload?.data.id),
      };
      break;
    }
    case "SET_PROCESS_CAMPAIGN": {
      return { ...state, updating: action.updating, sending: action.sending };
      break;
    }

    case "SAVE_CAMPAIGN_PENDING": {
      return { ...state, updating: true, updated: false };
      break;
    }
    case "SAVE_CAMPAIGN_FULFILLED": {
      const campaign = action.payload;
      let exist = false;
      const updatedItems = state.items.map((item) => {
        if (item.id === campaign.id) {
          exist = true;
          return { ...item, ...campaign };
        }
        return item;
      });
      return {
        ...state,
        updating: false,
        updated: true,
        sending: false,
        fetched: true,
        sent: true,
        error: null,
        items: exist ? updatedItems : [campaign],
      };
      break;
    }
    case "SAVE_CAMPAIGN_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        updating: false,
        updated: false,
        items: [],
        error,
      };
      break;
    }
    case "SET_EMAIL_PAGINATION_PAGE": {
      return { ...state, emailPaginationPage: action.pageNumber };
      break;
    }
    case "SET_SMS_PAGINATION_PAGE": {
      return { ...state, smsPaginationPage: action.pageNumber };
      break;
    }
    case "SET_EMAIL_PAGE_SIZE": {
      return {
        ...state,
        emailPageSize: action.emailPageSize,
        emailPaginationPage: 1,
      };
      break;
    }
    case "SET_SMS_PAGE_SIZE": {
      return {
        ...state,
        smsPageSize: action.smsPageSize,
        smsPaginationPage: 1,
      };
      break;
    }
    case "SET_CAMPAIGN_STATUS_FULFILLED": {
      const { data } = action.payload.data;
      let updatedItems = state.items.map((item) => {
        if (item.id === data.id) {
          return { ...item, ...data };
        }
        return item;
      });
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: updatedItems,
      };
      break;
    }
    case "COMPLETE_CAMPAIGN_FULFILLED": {
      const { data } = action.payload.data;
      let updatedItems = state.items.map((item) => {
        if (item.id === data.id) {
          return { ...item, ...data };
        }
        return item;
      });

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: updatedItems,
      };
    }
    case "SHARE_CAMPAIGN_FULFILLED": {
      const { data } = action.payload.data;
      let updatedItems = state.items.map((item) => {
        if (item.id === data.id) {
          return { ...item, ...data };
        }
        return item;
      });
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: updatedItems,
      };
    }
    case "COMPLETE_CAMPAIGN_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    }
    case "INIT_CAMPAIGN_LIST_ITEMS": {
      return { ...state, items: [] };
      break;
    }
    case "INIT_CAMPAIGN_LIST_FILTER": {
      return initialState;
      break;
    }
    case "SET_CAMPAIGN_LIST_FILTER_STATUS": {
      return {
        ...state,
        filter: { ...state.filter, status: action.status },
        emailPaginationPage: 1,
        smsPaginationPage: 1,
      };
      break;
    }
    case "SET_CAMPAIGN_LIST_FILTER_LANGUAGE": {
      return {
        ...state,
        filter: { ...state.filter, language: action.language },
        emailPaginationPage: 1,
        smsPaginationPage: 1,
      };
      break;
    }
    case "SET_CAMPAIGN_LIST_FILTER_SUBJECT": {
      return {
        ...state,
        filter: { ...state.filter, subject: action.subject },
        emailPaginationPage: 1,
      };
      break;
    }
    case "SET_CAMPAIGN_LIST_FILTER_TYPE": {
      return {
        ...state,
        filter: { ...state.filter, type: action.typeC },
        emailPaginationPage: 1,
      };
      break;
    }
    case "SET_CAMPAIGN_LIST_FILTER_RECIPIENT_TYPE": {
      return {
        ...state,
        filter: { ...state.filter, recipientType: action.typeR },
        emailPaginationPage: 1,
      };
    }
    case "SET_CAMPAIGN_LIST_FILTER_CAMPAIGN_NAME": {
      return {
        ...state,
        filter: { ...state.filter, campaignName: action.campaignName },
        smsPaginationPage: 1,
      };
      break;
    }
    case "SET_CAMPAIGN_LIST_FILTER_DATE_RANGE": {
      return {
        ...state,
        filter: { ...state.filter, dateRange: action.dateRange },
        emailPaginationPage: 1,
        smsPaginationPage: 1,
      };
    }
    case "CHANGE_SCHEDULED_AT_DATE_FULFILLED": {
      const { data } = action.payload.data;
      let updatedItems = state.items.map((item) => {
        if (item.id === data.id) {
          return { ...item, ...data };
        }
        return item;
      });
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: updatedItems,
      };
    }
    case "CHANGE_SCHEDULED_AT_DATE_REJECTED": {
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    }
    case "FETCH_RECIPIENT_CRITERIA_PENDING": {
      return { ...state, fetched: false, fetching: true };
      break;
    }
    case "FETCH_RECIPIENT_CRITERIA_FULFILLED": {
      const { data, nbResult } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        recipientCriteria: data,
        nbResult: nbResult,
      };
      break;
    }
    case "FETCH_RECIPIENT_CRITERIA_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        recipientCriteria: [],
        error,
      };
      break;
    }

    default:
      return state;
  }
};

const initialStateForCampaignsSort = {
  sortConfig: {
    columnKey: "updatedAt",
    sortDirection: "desc",
  },
};

export const campaignsSortReducer = (
  state = initialStateForCampaignsSort,
  action,
) => {
  switch (action.type) {
    case "SET_CAMPAIGN_LIST_SORT_CONFIG": {
      return { ...state, sortConfig: action.config };
      break;
    }
    default:
      return state;
  }
};

const initialStateForRecipientsFilter = {
  name: "",
  pageSize: 10,
  paginationPage: 1,
};

const initialStateForCampaignRecipient = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
  recipientsTotalCount: 0,
  nbResult: 0,
  filter: initialStateForRecipientsFilter,
  isUaVersion: false,
  uaRecipientsTotalCount: 0,
  totalUniqueRecipients: 0,
};

export const recipientsReducer = (
  state = initialStateForCampaignRecipient,
  action,
) => {
  switch (action.type) {
    case "FETCH_MEMBRSHIP_CLIENTS_LIST_PENDING": {
      return { ...state, fetched: false, fetching: true };
      break;
    }
    case "FETCH_MEMBRSHIP_CLIENTS_LIST_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error,
      };
      break;
    }
    case "FETCH_MEMBRSHIP_CLIENTS_LIST_FULFILLED": {
      const { data, nbResult } = action.payload.data;
      let recipientsTotalCount = state.filter.name
          ? state.recipientsTotalCount
          : nbResult;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult: nbResult || 0,
        recipientsTotalCount,
      };
      break;
    }
    case "INIT_CAMPAIGN_RECIPIENTS": {
      return initialStateForCampaignRecipient;
      break;
    }
    case "SET_UA_RECIPIENTS_TOTAL_COUNT": {
      return {
        ...state,
        uaRecipientsTotalCount: action.uaRecipientsTotalCount,
      };
      break;
    }
    case "SET_IS_UA_VERSION": {
      return { ...state, isUaVersion: action.isUaVersion };
      break;
    }

    case "FETCH_CAMPAIGN_RECIPIENTS_PENDING": {
      return { ...state, fetched: false, fetching: true, nbResult: 0 };
      break;
    }

    case "FETCH_CAMPAIGN_RECIPIENTS_FULFILLED": {
      const { data, nbResult } = action.payload.data;
      let recipientsTotalCount = state.isUaVersion
        ? nbResult
        : state.filter.name
          ? state.recipientsTotalCount
          : nbResult;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult: nbResult || 0,
        recipientsTotalCount,
      };
      break;
    }

    case "FETCH_CAMPAIGN_RECIPIENTS_REJECTED": {
      let error = action.payload;
      let recipientsTotalCount = state.filter.name
        ? state.recipientsTotalCount
        : 0;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        nbResult: 0,
        recipientsTotalCount,
        error,
      };
      break;
    }
    case "SET_RECIPIENTS_PAGINATION_PAGE": {
      return {
        ...state,
        filter: {
          ...state.filter,
          paginationPage: action.pageNumber,
        },
      };
      break;
    }
    case "SET_RECIPIENTS_PAGE_SIZE": {
      return {
        ...state,
        filter: {
          ...state.filter,
          pageSize: action.pageSize,
          paginationPage: 1,
        },
      };
      break;
    }
    case "INIT_CAMPAIGN_RECIPIENTS_FILTERS": {
      return {
        ...state,
        filter: initialStateForRecipientsFilter,
      };
      break;
    }
    case "SET_RECIPIENTS_LIST_FILTER_NAME": {
      return {
        ...state,
        filter: {
          ...state.filter,
          name: action.name,
          paginationPage: 1,
        },
      };
      break;
    }

    case "FETCH_CAMPAIGN_TOTAL_RECIPIENTS_PENDING": {
      return {
        ...state,
        fetched: false,
        fetching: true,
        totalUniqueRecipients: 0,
      };
      break;
    }

    case "FETCH_CAMPAIGN_TOTAL_RECIPIENTS_FULFILLED": {
      const { nbResult } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        totalUniqueRecipients: nbResult || 0,
      };
      break;
    }

    case "FETCH_CAMPAIGN_TOTAL_RECIPIENTS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        totalUniqueRecipients: 0,
        error,
      };
      break;
    }

    default:
      return state;
  }
};

const initialStateForEmailsReducer = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
};
export const emailsReducer = (state = initialStateForEmailsReducer, action) => {
  switch (action.type) {
    case "FETCH_EMAILS_PENDING": {
      return { ...state, fetched: false, fetching: true };
      break;
    }
    case "FETCH_EMAILS_FULFILLED": {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult: nbResult,
      };
      break;
    }
    case "FETCH_EMAILS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        nbResult: 0,
        error,
      };
      break;
    }
    default:
      return state;
  }
};

const initialStateForPhoneNumbersReducer = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
};
export const phoneNumbersReducer = (
  state = initialStateForPhoneNumbersReducer,
  action,
) => {
  switch (action.type) {
    case "FETCH_PHONE_NUMBERS_PENDING": {
      return { ...state, fetched: false, fetching: true };
      break;
    }
    case "FETCH_PHONE_NUMBERS_FULFILLED": {
      const { data, nbResult } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
        nbResult: nbResult,
      };
      break;
    }
    case "FETCH_PHONE_NUMBERS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        nbResult: 0,
        error,
      };
      break;
    }
    default:
      return state;
  }
};

const initialStateForCampaignBroadcastersReducer = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
};

export const campaignBroadcastersReducer = (
  state = initialStateForCampaignBroadcastersReducer,
  action,
) => {
  switch (action.type) {
    case "FETCH_CAMPAIGN_BROADCASTERS_PENDING": {
      return { ...state, fetched: false, fetching: true };
    }

    case "FETCH_CAMPAIGN_BROADCASTERS_FULFILLED": {
      const { data } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
      };
    }

    case "FETCH_CAMPAIGN_BROADCASTERS_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
      }

      return {
        ...state,
        fetching: false,
        items: [],
        nbResult: 0,
        error,
      };
    }

    default:
      return state;
  }
};

const initialStateForDelegationTypesReducer = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
};

export const delegationTypesReducer = (
  state = initialStateForDelegationTypesReducer,
  action,
) => {
  switch (action.type) {
    case "FETCH_DELEGATION_TYPE_PENDING": {
      return {
        ...state,
        fetched: false,
        fetching: true,
      };
    }
    case "FETCH_DELEGATION_TYPE_FULFILLED": {
      const { data } = action.payload.data;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        items: data,
      };
    }
    case "FETCH_DELEGATION_TYPE_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        items: [],
        error,
      };
    }

    default:
      return state;
  }
};
