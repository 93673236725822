export const initCurrentEmailCampaignData = (data = {}) => {
  return {
    type: "INIT_CURRENT_EMAIL_CAMPAIGN_DATA",
    data,
  };
};

export const fetchCurrentEmailCampaign = (resp) => {
  return {
    type: "FETCH_CURRENT_EMAIL_CAMPAIGN",
    payload: resp,
  };
};

export const saveCurrentEmailCampaign = (resp) => {
  return {
    type: "SAVE_CURRENT_EMAIL_CAMPAIGN",
    payload: resp,
  };
};

export const setNewsLetter = (active) => {
  return {
    type: "SET_NEWS_LETTER",
    active,
  };
};

export const setCurrentCampaignTheme = (theme) => {
  return {
    type: "SET_CURRENT_CAMPAIGN_THEME",
    theme,
  };
};

export const setCurrentEmailCampaignData = (data) => {
  return {
    type: "SET_CURRENT_EMAIL_CAMPAIGN_DATA",
    data,
  };
};
export const setUaNewsletterId = (uaNewsletterId) => {
  return {
    type: "SET_UA_NEWSLETTER_ID",
    uaNewsletterId,
  };
};
export const setIsUaNewsletter = (isUaNewsletter) => {
  return {
    type: "SET_IS_UA_NEWSLETTER",
    isUaNewsletter,
  };
};
export const setProgrammedDate = (programmedDate) => {
  return {
    type: "SET_PROGRAMMED_DATE",
    programmedDate,
  };
};

export const initCurrentSmsCampaignData = (data = null) => {
  return {
    type: "INIT_CURRENT_SMS_CAMPAIGN_DATA",
    data,
  };
};

export const setCurrentSmsCampaignData = (data) => {
  return {
    type: "SET_CURRENT_SMS_CAMPAIGN_DATA",
    data,
  };
};

export const setEventsFilters = (data) => {
  return {
    type: "SET_EVENTS_FILTERS",
    data,
  };
};

export const setTitleSelection = (titleSelection) => {
  return {
    type: "SET_TITLE_SELECTION",
    titleSelection,
  };
};

export const saveRecipientsCriteria = (resp) => {
  return {
    type: "SAVE_RECIPIENT_CRITERIA",
    payload: resp,
  };
};

export const setUaIdApproval = (idApproval) => {
  return {
    type: "SET_UA_ID_APPROVAL",
    idApproval,
  };
};

export const setUaApprovalAction = (approvalAction) => {
  return {
    type: "SET_UA_APPROVAL_ACTION",
    approvalAction,
  };
};

export const setIsNlBlog = (isNlBlog) => {
  return {
    type: "SET_IS_NL_BLOG",
    isNlBlog,
  };
};

export const setUaCmail = (cmail) => {
  return {
    type: "SET_UA_CMAIL",
    cmail,
  };
};