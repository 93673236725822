import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchSubscriptionDetails, toggleSubscription } from "thunks";
import _ from "i18n";
import { onSuccess, onError } from "utils";
import { clearAllCookies } from "../../services/auth";
import TTPLoader from "../ui/TTPLoader";
import { toggleSubscriptionTT } from "../../actions/thunks/subscriptionUA";

@connect((store) => {
  return {
    subscriptionData: store.subscriptionUA.subscriptionData,
    fetching: store.subscriptionUA.fetching,
    cmail: store.currentCampaign.uaNewsletter.cmail,
  };
})
export default class SubscriptionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscribed: false,
    };
  }

  componentDidMount() {
    const { dispatch, cmail } = this.props;
    dispatch(fetchSubscriptionDetails(cmail)).then(() => {
      const { subscriptionData } = this.props;
      this.setState({
        subscribed: subscriptionData ? subscriptionData.subscribed : false,
      });
    });
  }

  logout = () => {
    window.parent.postMessage("logout", "*");
  };

  toggleSubscription = () => {
    const { dispatch, subscriptionData, cmail } = this.props;
    const data = subscriptionData?.subscription;

    dispatch(toggleSubscription(data)).then(
      (res) => {
        onSuccess(res, {
          body: _("successfulAction"),
          title: _("successfulAction"),
        });
        // this.logout();
        this.setState({ subscribed: !this.state.subscribed });
      },
      (err) => onError(err),
    );
    dispatch(fetchSubscriptionDetails(cmail));
  };
  toggleSubscriptionTT = () => {
    const { dispatch, subscriptionData, cmail } = this.props;
    const data = subscriptionData?.subscription;

    dispatch(toggleSubscriptionTT(data)).then(
      (res) => {
        onSuccess(res, {
          body: _("successfulAction"),
          title: _("successfulAction"),
        });
        // this.logout();
        this.setState({ subscribed: !this.state.subscribed });
      },
      (err) => onError(err),
    );
    dispatch(fetchSubscriptionDetails(cmail));
  };

  render() {
    const { subscriptionData, fetching } = this.props;
    const { subscribed } = this.state;
    const community = subscriptionData ? subscriptionData.community : null;
    const email = subscriptionData ? subscriptionData.email : null;

    return (
      <>
        {fetching ? (
          <TTPLoader cssClass={"fixed-loader"} />
        ) : subscribed ? (
          <div className="subscription-item">
            <div className="text-center">
              <img src={community?.logo} />
            </div>
            <div className="subscription-block align-center">
              <h1 className="column subscription-header">
                {_("unsubscribe")} ?
              </h1>
              <div className="column subscription-content">
                {_("confirm_unsubscribe_msg")} <b> {email}</b>{" "}
                {_("from_future_mails_from")}
                <b> {community?.name}</b>
              </div>
              <div className="subscription-actions">
                <button className="btn secondary" onClick={this.logout}>
                  {_("cancel")}
                </button>
                <button
                  className="btn primary btn-unsubscribe"
                  onClick={this.toggleSubscription}
                >
                  {_("unsubscribe")}
                </button>
              </div>
              <div
                className="unsubscribe-from-tt"
                onClick={this.toggleSubscriptionTT}
              >
                <span> {_("unsubscribe_from_ua")} </span>
              </div>
            </div>
          </div>
        ) : (
          <div className="subscription-item">
            <div className="subscription-block align-center">
              <h1 className="column subscription-header">
                {_("unsubscribed_msg")}?
              </h1>
              <div className="column subscription-content">
                {_("warning_unsubscribe_msg")} <b>{community?.name}</b>
                {_("unsubscribed_by_accident")}
              </div>
              <div className="subscription-actions">
                <button className="btn secondary" onClick={this.logout}>
                  {_("cancel")}
                </button>
                <button
                  className="btn primary"
                  onClick={this.toggleSubscription}
                >
                  {_("subscribed_msg")}
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
