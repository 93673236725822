import React, { Component } from "react";
import { connect } from 'react-redux';

import _ from 'i18n';
import { setEventsFilters } from 'actions';
import { fetchGroups, fetchEvents } from 'thunks';
import TTPSelect from "common/TTPSelect";
import GroupsFilters from "./GroupsFilters";
import MailingListFilters from "./MailingListFilters";
import RangeSlider from "react-input-range";
import {setCurrentEmailCampaignData} from "../../../../actions/actions/currentCampaign";
import {ucFirst} from "../../../../services/common";
import {getSelectEventOptions} from "../../../../services/campaign";

@connect((store) => {
  return {
    events: store.filters.events.items,
    language: store.params.lng,
    campaignLanguage: store.currentCampaign.email.language,
    eventFilters: store.currentCampaign.email.eventFilters,
    commitmentScoreRange: store.currentCampaign.email.commitmentScoreRange,
  }
})
export default class ContactFilters extends Component {

  constructor(props) {
    super(props);
    const {commitmentScoreRange} = this.props;
    const commitmentScore = (typeof commitmentScoreRange === 'string') ? commitmentScoreRange.split(',') : commitmentScoreRange;
    this.state = {
      rangeValue: {min: commitmentScore[0] ?? commitmentScore.min , max: commitmentScore[1] ?? commitmentScore.max}
    };
  }

  handleChangeCommitmentScoreRange = () => {
    const {dispatch} = this.props;
    dispatch(setCurrentEmailCampaignData({commitmentScoreRange: this.state.rangeValue}));
  };

  componentWillMount() {
    const {recipientType, dispatch} = this.props;
    dispatch(fetchGroups(recipientType));
    dispatch(fetchEvents());
  }

  renderListOption(option) {
    return <div className="select-option">
      <span className="select-option__label">{option.label}</span>
      <span className="select-option__content">{option.subscribers} {_('subscribed contacts')}</span>
    </div>;
  }

  handleEventChange = (events) => {
    this.props.dispatch(setEventsFilters({ property: 'guest.event', operator: 'nin', value: events }))
  }

  render() {
    const { events, eventFilters, campaignLanguage } = this.props;

    let filterMapping = (eventFilters || []).reduce((filters, filter) => {
      let key = `${filter.property}-${filter.operator}`;
      filters[key] = filter.value;
      return filters;
    }, {});
    let excludedEventGuests = filterMapping['guest.event-nin'] || "";

    let lng = ucFirst(campaignLanguage);
    let eventOptions = events.map(event => {
      return {
        label: event[`name${lng}`] || event['nameFr'] || event['nameNl'] || event['nameEn'],
        id: event.id + ""
      };
    });

    return (
      <div>
        <div className="row">
          <MailingListFilters />
          <div className="columns small-6 columns-padding">
            <div className="sidebar__form-control">
              <h1 className="param__title">{_('exclude event registrants')}</h1>
              <TTPSelect
                values={excludedEventGuests}
                placeholder={_('Select event')}
                options={eventOptions}
                onChange={this.handleEventChange} />
            </div>
          </div>
        </div>
        <GroupsFilters />
        {/*<div className="row slider-container medium-4">*/}
        {/*  <h1 className="param__title">{_('commitment score')}</h1>*/}
        {/*  <RangeSlider*/}
        {/*    maxValue={100}*/}
        {/*    minValue={0}*/}
        {/*    value={this.state.rangeValue}*/}
        {/*    onChange={value => this.setState({ rangeValue: value })}*/}
        {/*    onChangeComplete={this.handleChangeCommitmentScoreRange} />*/}
        {/*</div>*/}
      </div>
    );
  }
}
