import React from "react";
import _ from "i18n";
import ActionBar from "./ActionBar";
import { connect } from "react-redux";
import { NotificationManager } from 'react-notifications';

import { setStep } from "actions";
import { getCampaignTypeData } from "./steps";
import { getHistory } from "../../../router/getHistory";
import { initRecipients, initCurrentEmailCampaignData, initNewsletterBlocks, setCurrentDndTheme, setCurrentNewsletterTemplate, setCurrentLayout, setCurrentEmailCampaignData, setNewsletterBlocks, setTTPDialogCustomData } from "actions";
import { CAMPAIGN_TYPES } from "../../../config/Common";
import { fetchCampaign, fetchGenericEmails } from "../../../actions/thunks";
import TTPLoader from "../../ui/TTPLoader";
import { initGECampaign, resolveTargetTypeFromQuery } from "../../../services/utils";
import { decomposeNewsletter } from "../../../services/block/newsletter";

import DIALOG_TYPES from "../../../constants/TTPDialog";
import { initTemplatesList } from "../../../actions/actions";

class CreateCampaignContainer extends React.Component {

  childRef = null;

  constructor(props) {
    super(props);

    let { location } = props;
    const query = new URLSearchParams(location.search);
    let type = (query.get("type")) ? query.get("type") : "text";
    let { steps, icon, title } = getCampaignTypeData(type);

    this.state = {
      type,
      steps,
      currentStepIndex: 0,
      icon,
      title,
      updateHash: Math.random() + "",
    };
  }

  validGeParameters = () => {
    const query = new URLSearchParams(this.props.location.search);
    return (
      query.get('targetApp') &&
      query.get('language') &&
      query.get('targetId') &&
      (query.get('targetType') ||
        (
          query.get('discriminant') &&
          query.get('rappelType')
        )
      )
    );
  }

  componentDidMount() {
    let { setStep, match: { params: routeParams }, location, initCurrentCampaign, initCampaignRecipients, fetchGenericEmails, setNewsletterBlocks, setCurrentNewsletterTemplate, currentClient } = this.props;
    setStep();
    const query = new URLSearchParams(location.search);

    const isNewsletter = this.state.type == "newsletter";
    const type = isNewsletter ? "dnd" : this.state.type;
    initCurrentCampaign({ type: CAMPAIGN_TYPES[type], newsLetter: isNewsletter ? 1 : 0 });
    initCampaignRecipients();

    if (routeParams.campaignId) {
      this.props.fetchCampaign(routeParams.campaignId).then((res) => {
        const { design, newsLetter, html, fields } = res.value?.data.data[0];
        if (design) {
          this.props.setCurrentDndTheme({ design });
        }
        if (design && newsLetter == 1) {
          const template = { design, content: html };
          try {
            setNewsletterBlocks(JSON.parse(fields));
          } catch (error) {
            decomposeNewsletter(template, blocks => setNewsletterBlocks(blocks));
          }
          setCurrentNewsletterTemplate(template);
        }

        this.setState(prevState => ({ currentStepIndex: prevState.steps?.length - 1 || 0 }));
      });
    } else if (this.validGeParameters()) {
      const filters = {
        targetApp: query.get('targetApp'),
        targetType: resolveTargetTypeFromQuery(query),
        language: query.get('language'),
        targetId: query.get('targetId'),
        organization: currentClient?.id
      };

      fetchGenericEmails(filters).then(res => {
        const { fromEmail, fromName, language, object, text, theme } = res.value.data.data[0];
        const data = {
          type: "PLAIN_TEXT",
          language,
          from: fromEmail,
          fromName,
          theme,
          object: object || "",
          html: text,
          mutatedContent: theme?.content ?? "",
          targetId: parseInt(query.get('targetId')),
          disableRecipientType: filters.targetType == "SURVEY_INVITATION",
          ...initGECampaign(query)
        };
        initCurrentCampaign(data);
        if (theme?.design) {
          this.props.setCurrentDndTheme({ design: theme?.design });
        }
        this.setState(prevState => ({ currentStepIndex: prevState.steps?.length - 1 || 0 }));
      }).catch(() => {
        NotificationManager.warning(_('Email not found'));
      });
    }
  }

  onStepChange = (currentStepIndex) => {
    this.childRef && this.childRef.goBack && this.childRef.goBack();
    this.setState({ currentStepIndex });
  };

  onAction = (actionCode) => {
    const action = this.childRef && this.childRef[actionCode];
    if (action) action();
  };

  proceed = (step) => {
    const { steps, currentStepIndex } = this.state;
    this.setState({
      currentStepIndex: (step && step < steps.length) ? step : currentStepIndex + 1
    });
  };

  goPrevious = () => {
    const { currentStepIndex } = this.state;
    this.setState({
      currentStepIndex:  currentStepIndex - 1
    });
  };

  confirmClose = () => {
    const { setTTPDialogCustomData } = this.props;
    if (this.childRef && this.childRef.close) {
      this.childRef.close();
    } else {
      setTTPDialogCustomData({
        "approvedAction": this.close,
        "message": _("Once you close your changes will be lost. Do you really want to continue?"),
        "type": DIALOG_TYPES["ALERT"],
      });
    }
  };

  close = () => {
    let { setStep } = this.props;
    setStep(null);
    getHistory().push("/campaign/selector");
  };

  triggerRender = () => {
    this.setState({ updateHash: Math.random() + "" })
  };

  componentWillUnmount() {
    this.props.setStep(null);
    this.props.initCurrentCampaign();
    this.props.initNewsletterBlocks();
    this.props.setCurrentDndTheme();
    this.props.initCurrentLayout();
    this.props.initSamples();
    this.props.setCurrentNewsletterTemplate();
  }

  render() {
    const { currentStepIndex, type, steps, icon, title } = this.state;
    const { currentCampaignFetching } = this.props;

    const Content = steps && steps[currentStepIndex] ? steps[currentStepIndex].body : null;

    return <div className="create-campaign">
      <ActionBar icon={icon} title={title} currentStepIndex={currentStepIndex} steps={steps}
        onChange={this.onStepChange} onAction={this.onAction} onClose={this.confirmClose}
        isSaved={this.childRef && this.childRef.isSaved && this.childRef.isSaved()}
        isUpdating={this.childRef && this.childRef.isUpdating && this.childRef.isUpdating()}
        campaignType={type}
      />
      <div className={"create-campaign__body"}>
        {currentCampaignFetching && <TTPLoader />}
        {!currentCampaignFetching && Content &&
          <Content campaignType={type} triggerRender={this.triggerRender} {...this.props} childRef={ref => this.childRef = ref} proceed={this.proceed} goPrevious={this.goPrevious} inCreateCampaign={true} />}
      </div>
    </div>;
  }
}

const mapStateToProps = store => ({
  currentCampaignFetching: store.currentCampaign.email.fetching || store.genericEmail.currentSetting.emails.fetching,
  currentCampaign: store.currentCampaign.email,
  currentClient: store.auth.currentClient,
});

const mapDispatchToProps = dispatch => ({
  setStep: (step = "CREATE_CAMPAIGN") => dispatch(setStep(step)),
  initCampaignRecipients: () => dispatch(initRecipients()),
  initCurrentCampaign: data => dispatch(initCurrentEmailCampaignData(data)),
  setCurrentCampaignData: data => dispatch(setCurrentEmailCampaignData(data)),
  initNewsletterBlocks: () => dispatch(initNewsletterBlocks()),
  setCurrentDndTheme: (data = null) => dispatch(setCurrentDndTheme(data)),
  initCurrentLayout: () => dispatch(setCurrentLayout(null)),
  setCurrentNewsletterTemplate: (data = null) => dispatch(setCurrentNewsletterTemplate(data)),
  fetchCampaign: id => dispatch(fetchCampaign(id)),
  setNewsletterBlocks: (data) => dispatch(setNewsletterBlocks(data)),
  fetchGenericEmails: filters => dispatch(fetchGenericEmails(filters)),
  setTTPDialogCustomData: data => dispatch(setTTPDialogCustomData(data)),
  initSamples: () => dispatch(initTemplatesList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateCampaignContainer);
