export const CAMPAIGN_DYNAMIC_FIELDS = {
  RECIPIENT: [
    {
      code: "{{RECIPIENT.LN}}",
      title: { fr: "nom", nl: "achternaam", en: "last name" }
    },
    {
      code: "{{RECIPIENT.FN}}",
      title: { fr: "prénom", nl: "Voornaam", en: "first name" }
    },
    {
      code: "{{RECIPIENT.EMAIL}}",
      title: { fr: "email", nl: "email", en: "email" }
    }
  ],
  PAYMENT: [
    {
      code: "{{DOCUMENT.AMOUNT}}",
      title: { fr: "montant", nl: "bedrag ", en: "amount" }
    },
    {
      code: "{{DOCUMENT.COMMENT}}",
      title: { fr: "commentaire", nl: "commentaar", en: "comment" }
    },
    {
      code: "{{DOCUMENT.OFFICIAL_NUMBER}}",
      title: {
        fr: "numéro officiel",
        nl: "officieel nummer",
        en: "official number"
      }
    },
    {
      code: "{{DOCUMENT.LINK}}",
      title: {
        fr: "lien du document",
        nl: "document link",
        en: "document link"
      }
    },
    {
      code: "{{DOCUMENT.APP_TYPE}}",
      title: {
        fr: "type d'inscription",
        nl: "registratie type",
        en: "registration type"
      }
    },
    // ],
    // PAYMENT_RELANCE: [
    {
      code: "{{REMINDER.USER_NAME}}",
      title: { fr: "Nom", nl: "Naam", en: "Name" }
    },
    {
      code: "{{REMINDER.EVENT_NAME}}",
      title: {
        fr: "Nom de l'événement ",
        nl: "Evenement naam",
        en: "Event name"
      }
    },
    {
      code: "{{REMINDER.EVENT_DATE}}",
      title: {
        fr: "Date de l'événement",
        nl: "Evenement datum",
        en: "Event Date"
      }
    },
    {
      code: "{{REMINDER.LINK}}",
      title: {
        fr: "Lien de l'inscription",
        nl: "Registratielink",
        en: "Registration link"
      }
    },
    {
      code: "{{REMINDER.APP_TYPE}}",
      title: {
        fr: "Type d'inscription",
        nl: "Registratie type",
        en: "Registration type"
      }
    },
    {
      code: "{{SUBSCRIPTION.LINK}}",
      title: {
        fr: "Lien de l'abonnement",
        nl: "Abonnementslink",
        en: "Subscription link"
      }
    }
  ],
  SURVEY: [
    {
      code: "{{SURVEY.TITLE}}",
      title: {
        fr: "titre de l'enquête",
        nl: "titel van de enquête",
        en: "survey title"
      }
    },
    {
      code: "{{SURVEY.URL}}",
      title: { fr: "url de l'enquête", nl: "survey url", en: "survey url" }
    }
  ],
  UNITED_ASSOCIATES: [
    {
      code: "{{UA.MAIN_LINK}}",
      title: { fr: "lien principal", nl: "hoofdlink", en: "main link" }
    },
    {
      code: "{{UA.ACCEPT_INVITATION_LINK}}",
      title: {
        fr: "lien d'acceptation de l'invitation",
        nl: "uitnodiging accepteren link",
        en: "invitation accept link"
      }
    },
    {
      code: "{{UA.REJECT_INVITATION_LINK}}",
      title: {
        fr: "lien de refus de l'invitation",
        nl: "uitnodiging afwijzen link",
        en: "invitation reject link"
      }
    },
    {
      code: "{{UA.FIDUCIARY_NAME}}",
      title: {
        fr: "nom fiduciaire",
        nl: "vertrouwenspersoon",
        en: "fiduciary name"
      }
    },
    {
      code: "{{UA.CREATE_NEWSLETTER_LINK}}",
      title: {
        fr: "Lien de création de la newsletter",
        nl: "Link voor het maken van de nieuwsbrief",
        en: "Link to create the newsletter"
      }
    },
    {
      code: "{{UA.NEWSLETTER_OBJECT}}",
      title: {
        fr: "Objet de la newsletter",
        nl: "Onderwerp nieuwsbrief",
        en: "Newsletter object"
      }
    },
    {
      code: "{{UA.NEWSLETTER_SENDER}}",
      title: {
        fr: "Expéditeur de la newsletter",
        nl: "Afzender van de nieuwsbrief",
        en: "Sender of the newsletter"
      }
    },
    {
      code: "{{UA.NEWSLETTER_SENDER_NAME}}",
      title: {
        fr: "Nom d'expéditeur de la newsletter",
        nl: "Afzender van de nieuwsbrief",
        en: "Sender name of the newsletter"
      }
    },
    {
      code: "{{UA.NEWSLETTER_LANGUAGE}}",
      title: {
        fr: "Langue de la newsletter",
        nl: "Taal van de nieuwsbrief",
        en: "Language of the newsletter"
      }
    },
    {
      code: "{{UA.NEWSLETTER_RECIPIENT}}",
      title: {
        fr: "Destinataire de la newsletter",
        nl: "Ontvanger van de nieuwsbrief",
        en: "Recipient of the newsletter"
      }
    },
    {
      code: "{{UA.NEWSLETTER_SENT_DATE}}",
      title: {
        fr: "Date d'envoie",
        nl: "Datum verzending",
        en: "Date of sending"
      }
    },
    {
      code: "{{UA.NEWSLETTER_SENT_TIME}}",
      title: {
        fr: "Temps d'envoie",
        nl: "Tijd van verzending",
        en: "Sending time"
      }
    }
  ],
  GDPR: [
    {
      code: "{{OPT_IN_URL}}",
      title: { fr: "url d'opt in", nl: "opt in url", en: "opt in url" }
    },
    {
      code: "{{OPT_OUT_URL}}",
      title: { fr: "url d'opt out", nl: "opt out url", en: "opt out url" }
    }
  ]
};

export const RECIPIENT_TYPE = "RECIPIENT";
export const EVENT_TYPE = "EVENT";
export const WTB_TYPE = "WTB";
