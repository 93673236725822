export const htmlDecode = (strData) => {
  if (undefined === strData) {
    return "";
  }

  return strData.replace(/&#(\d+);/g, (match, dec) => {
    return String.fromCharCode(dec);
  });
};

export const unescapeHtml = (safe) => {
  return safe
    ?.replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, `"`)
    .replace(/&#039;/g, `'`);
};

export const purifyString = (string) => {
  return htmlDecode(unescapeHtml(string));
};

export function getNameByLanguage(property, currentLanguage) {
  const languageMapping = {
    fr: "nameFr",
    en: "nameEn",
    nl: "nameNl",
  };

  const currentLangKey = languageMapping[currentLanguage];
  if (
    property[currentLangKey] != null &&
    property[currentLangKey].trim() !== ""
  ) {
    return property[currentLangKey];
  }

  let languages = ["nameFr", "nameEn", "nameNl"].filter(
    (e) => e !== currentLangKey,
  );

  for (var i = 0; i < languages.length; i++) {
    let lng = languages[i];

    if (property[lng] != null && property[lng].trim() !== "") {
      return property[lng];
    }
  }

  return "";
}

export const ucFirst = (text) => {
  return text && text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};

const getIcon = (icon) => `/img/toolbar/${icon}.svg`;

export const getEditorToolbarConfig = (mode = "FULL") => {
  const toolBar = {
    options: [
      "history",
      "fontFamily",
      "fontSize",
      "blockType",
      "inline",
      "list",
      "colorPicker",
      "image",
      "remove",
      "textAlign",
      "emoji",
    ],
    history: {
      undo: { icon: getIcon("undo"), className: "toolbar__option-custom" },
      redo: { icon: getIcon("redo"), className: "toolbar__option-custom" },
    },
    inline: {
      options: [
        "italic",
        "bold",
        "underline",
        "strikethrough",
        "superscript",
        "subscript",
      ],
      bold: { icon: getIcon("bold"), className: "toolbar__option-custom" },
      italic: { icon: getIcon("italic"), className: "toolbar__option-custom" },
      underline: {
        icon: getIcon("underline"),
        className: "toolbar__option-custom",
      },
      strikethrough: {
        icon: getIcon("strikethrough"),
        className: "toolbar__option-custom",
      },
      monospace: {
        icon: getIcon("monospace"),
        className: "toolbar__option-custom",
      },
      superscript: {
        icon: getIcon("superscript"),
        className: "toolbar__option-custom",
      },
      subscript: {
        icon: getIcon("subscript"),
        className: "toolbar__option-custom",
      },
    },
    blockType: {
      className: "toolbar__option-custom-wide",
      dropdownClassName: "toolbar__dropdown-custom",
    },
    fontSize: { className: "toolbar__option-custom-wide" },
    list: {
      options: ["unordered", "ordered", "indent", "outdent"],
      unordered: {
        icon: getIcon("list-1"),
        className: "toolbar__option-custom",
      },
      ordered: { icon: getIcon("list"), className: "toolbar__option-custom" },
      indent: { icon: getIcon("indent"), className: "toolbar__option-custom" },
      outdent: {
        icon: getIcon("outdent"),
        className: "toolbar__option-custom",
      },
    },
    textAlign: {
      left: {
        icon: getIcon("left-align"),
        className: "toolbar__option-custom",
      },
      center: {
        icon: getIcon("center-align"),
        className: "toolbar__option-custom",
      },
      right: {
        icon: getIcon("right-align"),
        className: "toolbar__option-custom",
      },
      justify: {
        icon: getIcon("justify"),
        className: "toolbar__option-custom",
      },
    },
    fontFamily: {
      className: "toolbar__option-custom-wide",
      dropdownClassName: "toolbar__dropdown-custom",
    },
    colorPicker: {
      icon: getIcon("dropper"),
      className: "toolbar__option-custom",
      popupClassName: "toolbar__popup-custom",
    },
    link: {
      popupClassName: "toolbar__popup-custom",
      showOpenOptionOnHover: false,
      defaultTargetOption: "",
      link: { icon: getIcon("link"), className: "toolbar__option-custom" },
      unlink: {
        icon: getIcon("broken-link"),
        className: "toolbar__option-custom",
      },
    },
    image: {
      icon: getIcon("image"),
      className: "toolbar__option-custom",
      popupClassName: "toolbar__popup-custom",
    },
    emoji: {
      icon: getIcon("emoji"),
      className: "toolbar__option-custom",
      popupClassName: "toolbar__popup-custom",
    },
    remove: { icon: getIcon("eraser"), className: "toolbar__option-custom" },
  };

  if (mode === "REDUCED") {
    return {
      ...toolBar,
      options: [
        "history",
        "fontFamily",
        "fontSize",
        "inline",
        "colorPicker",
        "link",
        "image",
        "remove",
        "emoji",
        "textAlign",
        "list",
      ],
    };
  }
  return toolBar;
};
