import axios from "../axios-api";

export const getSubscriptionDetails = async (token, cmail) => {
  const requestUrl = `/mailing/subscription/details`;

  try {
    const response = await axios.get(requestUrl, {
      params: {
        access_token: token,
        cmail: cmail,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching subscription details:", error);
    throw error;
  }
};

export const updateSubscription = (token, data) => {
  const requestUrl = `/mailing/toggle/subscription`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("cmail", data.cmail);
  formData.append("subscriberId", data.id);
  formData.append("type", data.type);

  return axios.post(requestUrl, formData);
};

export const updateSubscriptionTT = (token, data) => {
  const requestUrl = `/mailing/toggle/subscriptionForTT`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("cmail", data.cmail);
  formData.append("subscriberId", data.id);
  formData.append("type", data.type);
  formData.append("unsubscibedFromTT", 1);

  return axios.post(requestUrl, formData);
};
