import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "i18n";
import {
  setEboxMailData,
  setEBoxPaginationPage,
  setIsFolderEbox,
} from "../../../actions/actions/eBox";
import TTPPaginator from "../../common/list/TTPPaginator";
import EBoxFetching from "../../fetching/EBoxFetching";
import { EMAIL_SENT_STATUS } from "../../../config/Common";
import NotFound from "notFound/NotFound";
import { MailItem } from "./MailItem";
import MailViewer from "../viewer/MailViewer";
import { setTTPDialogCustomData } from "../../../actions/actions/params";
import { onError, onSuccess, SVG_RESEND_ICON } from "../../../services/utils";
import { reSendToRecipient, deleteEBoxEmail } from "thunks";
import { getEBoxMailData } from "../../../actions/thunks/eBox";
import { SVG_DELETE_ICON } from 'utils';
import DIALOG_TYPES from "../../../constants/TTPDialog";
import ListFilter from "./ListFilter";

@connect((store) => (
  {
    language: store.params.lng,
    ebox: store.ebox.list,
    filter: store.ebox.list.filter,
    owner: store.ebox.owner.data,
    mailData: store.ebox.list.eBoxMailData.mailData,
    fetching: store.ebox.list.eBoxMailData.fetching,
    count: store.ebox.list.count,
    currentClient: store.auth.currentClient
  }
), (dispatch) => ({
  deleteEBoxEmail: (id) => dispatch(deleteEBoxEmail(id)),
  reSendToRecipient: (recipient) => dispatch(reSendToRecipient(recipient)),
  setTTPDialogCustomData: (data) => dispatch(setTTPDialogCustomData(data)),
  setEBoxPaginationPage: (selected) => dispatch(setEBoxPaginationPage(selected + 1)),
  setEboxMailData: (mailData) => dispatch(setEboxMailData(mailData)),
  getEBoxMailData: (id) => dispatch(getEBoxMailData(id)),
  setIsFolderEbox: (flag) => dispatch(setIsFolderEbox(flag)),
})
)
export default class List extends Component {

  constructor(props) {
    super(props);
    this.statusOptions = EMAIL_SENT_STATUS.map(({ title, value, color }) => {
      return { label: _(title), value, color }
    });

    this.state = {
      isViewerOpen: false,
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.currentClient !== prevProps.currentClient) {
      this.props.setIsFolderEbox(false);
    }
  }

  renderPaginate() {
    const { setEBoxPaginationPage, ebox, filter: { pageSize, paginationPage } } = this.props;
    if (ebox.error != null && ebox.error.code == 404) {
      return "";
    }
    return <TTPPaginator onPageChange={({ selected }) => setEBoxPaginationPage(selected)}
      pageSize={pageSize}
      nbResult={ebox.nbResult}
      paginationPage={paginationPage} />;
  }

  viewEBoxPreview = (item) => {
    const mailData = {
      ...item.campaign,
      id: item.id,
      date: item.sentAt || item.createdAt,
      mainEmail: item.mainEmail,
    };
    this.props.setEboxMailData(mailData);
    this.props.getEBoxMailData(item.id);
    this.setState({ isViewerOpen: true });
  };

  onViewerClose = () => {
    this.setState({ isViewerOpen: false });
  };

  handleResendMail = (recipient) => {
    this.props.setTTPDialogCustomData({
      "approvedAction": () => this.reSendEmail(recipient),
      "message": _("confirmResendEmail"),
      "proceedBtnLabel": _("yesSend"),
      "abortBtnLabel": _("no"),
      'title': "Confirmation of sending",
      'svgIcon': SVG_RESEND_ICON
    });
  };

  handleDeleteMail = (id) => {
    this.props.setTTPDialogCustomData({
      "approvedAction": () => this.deleteEmail(id),
      "message": _("confirmDeleteEmail"),
      "proceedBtnLabel": _("yesDelete"),
      "abortBtnLabel": _("no"),
      'title': "Confirmation of deletion",
      'type': DIALOG_TYPES["ALERT"],
      'svgIcon': SVG_DELETE_ICON
    });
  };

  deleteEmail = (id) => {
    this.props.deleteEBoxEmail(id).then(res => onSuccess(res), err => onError(err));
  };

  reSendEmail = (recipient) => {
    this.props.reSendToRecipient(recipient).then(res => onSuccess(res), err => onError(err));
  };

  render() {
    const { ebox: { items, fetching }, inOtherEbox, owner, language, filter } = this.props;
    const { isViewerOpen } = this.state;
    return (
      <div>

        {(!inOtherEbox || owner) &&
          <ListFilter />
        }
        {fetching ? <EBoxFetching /> :
          (items && items.length > 0) ?
            <div className="small-12 small-centered columns">
              {items.map(item => <MailItem key={item.id} item={item} language={language} status={filter.status}
                viewEBoxPreview={this.viewEBoxPreview}
                handleResendMail={this.handleResendMail}
                handleDeleteMail={this.handleDeleteMail} />)}
              {this.renderPaginate()}
            </div> : inOtherEbox && !owner ? null : <NotFound mainClass="small-12" text='ebox_empty' />
        }
        {isViewerOpen && <MailViewer isOpen={true} onClose={this.onViewerClose} handleResendMail={this.handleResendMail} inOtherEbox={inOtherEbox} />}
      </div>
    )
  }
}
