import Loader from "react-loaders";
import React from "react";
import _ from "i18n";


export const LoadingSuggestion = ({containerProps, loadingsSuggestions, suggestionsError, children, value}) => {
  return <div {...containerProps}>
    {loadingsSuggestions ?
      <div className="loading-suggestion">{_("Loading suggestions ...")} <Loader
        type="line-spin-fade-loader"/></div>
      : children
    }
    {(!loadingsSuggestions && suggestionsError && suggestionsError.code === 404 && value) &&
    <div className="loading-suggestion"> {_("Nothing Found")}</div>
    }
  </div>
};
