import React, { Component } from "react";
import { connect } from "react-redux";
import uuid from "uuid";

import {
  setRecipientsListFilterName,
  setRecipientsPageSize,
  setRecipientsPaginationPage,
} from "actions";
import { RECIPIENT_ATTRIBUTE, EVENT_RECIPIENT_TYPES } from "Common";
import _ from "i18n";
import TTPPaginator from "common/list/TTPPaginator";
import SearchBox from "common/filter/SearchBox";
import PageSize from "common/list/PageSize";
import NotFound from "notFound/NotFound";
import "react-input-range/lib/css/index.css";
import RangeSlider from "react-input-range";
import { setCurrentEmailCampaignData } from "../../../actions/actions/currentCampaign";
import ReactTooltip from "react-tooltip";
import { fixContactEmail } from "../../../actions/thunks/user";
import { onError, onSuccess } from "../../../services/utils";
import { initMailingList } from "../../../actions/actions/mailingList";
import Target from "../../common/campaign/Target";

@connect((store) => {
  return {
    recipients: store.campaigns.recipients,
    commitmentScoreRange: store.currentCampaign.email.commitmentScoreRange,
  };
})
export default class Recipients extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedTab: null, selectedValidEmail: "" };
  }

  componentDidUpdate(prevProps) {
    const { renderRecipientDatatable, recipientType } = this.props;
    if (
      recipientType !== prevProps.recipientType &&
      (recipientType === "CONTACT" || recipientType === "OECCBB_MEMBERS")
    ) {
      renderRecipientDatatable();
    }
  }

  handleSearch = (value) => {
    const { dispatch } = this.props;
    dispatch(setRecipientsListFilterName(value));
  };

  handleRecipientListPageClick(data) {
    const { dispatch } = this.props;
    let selectedPage = data.selected;
    dispatch(setRecipientsPaginationPage(selectedPage + 1));
  };

  handleSelectRecipientListPageSize = (pageSize) => {
    const { dispatch } = this.props;
    dispatch(setRecipientsPageSize(pageSize));
  };

  shouldComponentUpdate(nextProps, nextState) {
    let { recipients } = nextProps;

    if (recipients?.items?.length !== this.props.recipients?.items?.length) {
      window.dispatchEvent(new Event("resize"));
    }
    // Don't update component during ajax fetch new data process
    let condition1 = !recipients.fetching && recipients.fetched;
    // Result not found
    let condition2 = !recipients.items.length || !recipients.error;

    return condition1 || condition2;
  };

  getRecipientName(recipient) {
    let { recipientType, language } = this.props;
    const attributes = RECIPIENT_ATTRIBUTE[recipientType];

    let lng =
      ["fr", "nl", "en"].indexOf(language) > -1
        ? language.charAt(0).toUpperCase() + language.slice(1).toLowerCase()
        : "Fr";

    if ("PARTNER" === recipientType) {
      return (
        recipient[`name${lng}`] || recipient["nameFr"] || recipient["nameNl"]
      );
    }
    let fullName = `${recipient[attributes.lastName] || ""} ${
      attributes.firstName ? recipient[attributes.firstName] || "" : ""
    }`;

    return fullName || "--";
  }

  getRecipientEmail(recipient) {
    let { recipientType, language } = this.props;
    const attributes = RECIPIENT_ATTRIBUTE[recipientType];

    let lng =
      ["fr", "nl", "en"].indexOf(language) > -1
        ? language.charAt(0).toUpperCase() + language.slice(1).toLowerCase()
        : "Fr";

    if ("PARTNER" === recipientType) {
      return (
        recipient[`email${lng}`] || recipient["emailFr"] || recipient["emailNl"]
      );
    }

    return recipient[attributes.email] || recipient["mainEmail"] || "";
  }

  handleFixContactEmail = (contactData, selectedValidEmail) => {
    const { dispatch, renderRecipientDatatable } = this.props;
    let contactClientId = contactData.contactClient.id;
    let validEmail =
      contactData.validEmails.length > 1
        ? selectedValidEmail
        : contactData.validEmails;
    dispatch(fixContactEmail(contactClientId, validEmail, contactData.id)).then(
      (res) => {
        onSuccess(res);
        renderRecipientDatatable();
      },
      (err) => onError(err)
    );
  };

  handleValidEmailChange = (event) => {
    this.setState({ selectedValidEmail: event.target.value });
  };

  render() {
    const {
      recipients,
      recipientType,
      renderRecipientDatatable,
      invalidContactsEmail,
    } = this.props;
    const { selectedTab, selectedValidEmail } = this.state;

    if (!recipientType || recipientType === "MANUAL") {
      return null;
    }
    let notfoundBlock = <NotFound />;
    let targets = recipientType === "OECCB_MEMBERS" ? recipients.items.map((recipient) => recipient.user) : recipients.items.map((recipient) =>
      recipient.user && typeof recipient.user == "object"
        ? recipient.user
        : recipient
    );

    return (
      <div>
        {recipientType === "CONTACT" ? (
          <div
            className="small-12 list-filter__bar"
            style={{ display: "unset" }}
          >
            <div
              id="ttp-horizontal-filter"
              className={`ttp-medium-9`}
              style={{ margin: "1.2rem 1rem" }}
            >
              <div className="filter-wrapper">
                {recipientType === "CONTACT" && invalidContactsEmail > 0 &&
                <div className="filter-wrapper__tabs">
                  {
                    [{
                      label: _("all"),
                      value: null
                      },
                      {
                      label: _("Invalid emails"),
                      value: "BOUNCED",
                      color: "#e6140a"
                      }
                    ].map(({value, label, color}) =>
                    <span key={"tab--" + value}
                          className={`${selectedTab === value ? "active" : ""}`}
                          style={color ? { borderColor: color } : {}}
                          onClick={() => {
                          renderRecipientDatatable(value);
                          this.setState({selectedTab: value})
                          }}
                    >
                          {_(label)}
                    </span>)}
                </div>}
                {EVENT_RECIPIENT_TYPES.indexOf(recipientType) === -1 &&
                <SearchBox onChange={this.handleSearch}/>}
                <PageSize pageSize={recipients.filter.pageSize}
                          onChange={this.handleSelectRecipientListPageSize.bind(this)}/>
              </div>
            </div>
          </div>
        ) : (
          <div className="small-10 list-filter__bar">
            {EVENT_RECIPIENT_TYPES.indexOf(recipientType) === -1 && recipientType !== 'OECCBB_MEMBERS' && (
              <SearchBox onChange={this.handleSearch} />
            )}
            <PageSize
              pageSize={recipients.filter.pageSize}
              onChange={this.handleSelectRecipientListPageSize.bind(this)}
            />
          </div>
        )}
        {recipients.items.length === 0 && !recipients.fetching && recipients.fetched ? (
          notfoundBlock
        ) : (
          <div>
            <div className="row align-center">
              {recipientType === "CONTACT" ? (
                <div className="ttp-datatable small-12 columns p-l-l p-r-l">
                  <div className="row ttp-datatable__header">
                    <div className="small-3">{_("name")}</div>
                    <div className="small-4">{_("email")}</div>
                    <div className="small-4">{_("Replace with")}</div>
                  </div>
                  {targets.map((target) => {
                    return (
                      <div key={uuid()} className="row ttp-datatable__row">
                        <div className="small-3">
                          {this.getRecipientName(target)}
                        </div>
                        <div
                          data-class="tooltip-bounce"
                          data-for={"bounced" + target.id}
                          data-tip={
                            target?.isblackListed
                              ? "Black List Email (" +
                                target?.blackListingReason +
                                ")"
                              : target.mainClientEmailIsBounced
                              ? _("This email address is no longer valid")
                              : ""
                          }
                          className="small-4"
                          style={
                            target.mainClientEmailIsBounced !== 0 ||
                            target?.isblackListed
                              ? { color: "#F9626D", position: "relative" }
                              : {}
                          }
                        >
                          {(target.mainClientEmailIsBounced !== 0 ||
                            target?.isblackListed) && (
                            <img
                              className="p-r-xxs"
                              src="img/icons/alert-triangle.svg"
                            />
                          )}
                          {this.getRecipientEmail(target)}
                          {(target.mainClientEmailIsBounced !== 0 ||
                            target?.isblackListed) && (
                            <ReactTooltip
                              id={"bounced" + target.id}
                              type="error"
                              place="top"
                              effect={"solid"}
                              overridePosition={() => ({
                                top: -40,
                                left: 0,
                              })}
                              className={"tooltip-bounce"}
                            />
                          )}
                        </div>
                        {target?.mainClientEmailIsBounced !== 0 ? (
                          <div className="valid-email small-4">
                            {target?.validEmails?.length > 1 ? (
                              <select
                                className="ttp-page-size"
                                value={selectedValidEmail}
                                onChange={this.handleValidEmailChange}
                              >
                                {target?.validEmails.map((email, index) => (
                                  <option key={`email-${index}`} value={email}>
                                    {email}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              target?.validEmails?.length === 1 && target.validEmails[0]
                            )}
                            <button
                              className="btn success"
                              onClick={() =>
                                this.handleFixContactEmail(
                                  target,
                                  selectedValidEmail
                                )
                              }
                            >
                              {_("Fix")}
                            </button>
                          </div>
                        ) : (
                          <div className="valid-email small-4" />
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="ttp-datatable small-10 columns">
                  <div className="row ttp-datatable__header">
                    <div className="small-5">{_("name")}</div>
                    <div className="small-5">{_("email")}</div>
                  </div>
                  {targets.map((target) => {
                    return (
                      <div key={uuid()} className="row ttp-datatable__row">
                        <div className="small-5">
                          {this.getRecipientName(target)}
                        </div>
                        <div
                          data-for={"bounced" + target.id}
                          data-tip={
                            target?.isblackListed
                              ? "Black List Email (" +
                                target?.blackListingReason +
                                ")"
                              : _("This email address is no longer valid")
                          }
                          className="small-5"
                          style={
                            target.mainClientEmailIsBounced === 0 ||
                            target?.isblackListed
                              ? { color: "#F9626D", position: "relative" }
                              : {}
                          }
                        >
                          {(target.mainClientEmailIsBounced === 0 ||
                            target?.isblackListed) && (
                            <ReactTooltip
                              id={"bounced" + target.id}
                              type={"error"}
                              effect={"solid"}
                              overridePosition={() => ({
                                top: -40,
                                left: 0,
                              })}
                              className={"tooltip-bounce"}
                            />
                          )}
                          {(target.mainClientEmailIsBounced === 0 ||
                            target?.isblackListed) && (
                            <img
                              className="p-r-xxs"
                              src="img/icons/alert-triangle.svg"
                            />
                          )}
                          {this.getRecipientEmail(target)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <TTPPaginator
              onPageChange={this.handleRecipientListPageClick.bind(this)}
              pageSize={recipients.filter.pageSize}
              nbResult={recipients.nbResult}
              paginationPage={recipients.filter.paginationPage}
            />
          </div>
        )}
      </div>
    );
  }
}
