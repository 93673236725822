import React, { Component } from "react";
import { connect } from 'react-redux';

import { setEventsFilters } from 'actions';
import { fetchEventOptions, fetchEventPlan, fetchEvents } from 'thunks';
import _ from 'i18n';
import { GUEST_STATUS } from 'Common';
import TTPSelect from "common/TTPSelect";
import {ucFirst} from "../../../../../services/common";

@connect((store) => {
  return {
    events: store.filters.events.items,
    slots: store.filters.slots.items,
    options: store.filters.options.items,
    formulas: store.filters.formulas.items,
    language: store.params.lng,
    eventFilters: store.currentCampaign.email.eventFilters,
    campaignLanguage: store.currentCampaign.email.language
  }
}, (dispatch) => {

  const handleStepsChange = (dispatch, steps, operatorStep, operatorPrinted) => {
    const hasPrinted = steps.includes('105');
    const otherSteps = steps.filter(step => step !== '105');
    const filters = [];


    if (otherSteps.length > 0) {
      filters.push({ property: 'step', operator: operatorStep, value: otherSteps });
    } else {
      filters.push({ property: 'step', operator: operatorStep, value: '' })
    }

    if (hasPrinted) {
      filters.push({ property: 'printed', operator: operatorPrinted, value: 1 });
    } else {
      filters.push({ property: 'printed', operator: operatorPrinted, value: '' });
    }

    filters.forEach((filter) => {
      dispatch(setEventsFilters(filter))
    });
  };

  return {
    handleEventChange: (eventId) => dispatch(setEventsFilters({ property: 'event.id', operator: 'eq', value: eventId })),
    handleSlotChange: (slots) => dispatch(setEventsFilters({ property: 'slots', operator: 'in', value: (slots.length > 0) ? slots : "" })),
    handleOptionChange: (options) => dispatch(setEventsFilters({ property: 'guestOption.option', operator: 'in', value: (options.length > 0) ? options : "" })),
    handlePlanChange: (plan) => dispatch(setEventsFilters({ property: 'guestPlan.plan', operator: 'in', value: (plan.length > 0) ? plan : "" })),
    handleStepsInChange: (steps) => handleStepsChange(dispatch, steps, 'in', 'eq'),
    handleStepsNinChange: (steps) => handleStepsChange(dispatch, steps, 'nin', 'neq'),
    fetchEvents: () => dispatch(fetchEvents()),
    fetchEventPlan: (eventId) => dispatch(fetchEventPlan(eventId)),
    fetchEventOptions: (eventId) => dispatch(fetchEventOptions(eventId)),
  }
})
export default class GuestFilters extends Component {

  componentWillMount() {
    const { fetchEvents, fetchEventPlan, fetchEventOptions, eventFilters } = this.props;
    fetchEvents();
    for (let criteria of eventFilters) {
      if (criteria.property == "event.id") {
        fetchEventPlan(criteria.value);
        fetchEventOptions(criteria.value);
        break;
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { events, handleEventChange, eventFilters, fetchEventPlan, fetchEventOptions } = nextProps;
    if (events && events.length > 0 && eventFilters.length == 0) {
      handleEventChange(events[0]['id']);
      fetchEventPlan(events[0]['id']);
      fetchEventOptions(events[0]['id']);
    }
  }

  handleEventChange(event) {
    const { handleEventChange, fetchEventPlan, fetchEventOptions, handleOptionChange, handleStepsNinChange, handlePlanChange, handleStepsInChange } = this.props;
    fetchEventPlan(event);
    fetchEventOptions(event);
    handleEventChange(event);

    //init all
    handlePlanChange([]);
    handleOptionChange([]);
    handleStepsNinChange([]);
    handleStepsInChange([]);
  }

  render() {
    const { events, options, formulas, eventFilters, language, handlePlanChange, handleStepsNinChange, handleStepsInChange, handleOptionChange, campaignLanguage } = this.props;

    let filterMapping = eventFilters.reduce((filters, filter) => {
      let key = `${filter.property}-${filter.operator}`;
      filters[key] = filter.value;
      return filters;
    }, {});

    let selectedEvent = filterMapping['event.id-eq'] || "";
    let includedSteps = filterMapping['step-in'] || [];
    let excludedSteps = filterMapping['step-nin'] || [];
    let selectedPlan = filterMapping['guestPlan.plan-in'] || [];
    let selectedOption = filterMapping['guestOption.option-in'] || [];
    let isIncludedPrinted = filterMapping['printed-eq'] === 1;
    let isExcludedPrinted = filterMapping['printed-neq'] === 1;
    if (isIncludedPrinted && !includedSteps.includes('105')) {
      includedSteps = [...includedSteps, '105'];
    }

    if (isExcludedPrinted && !excludedSteps.includes('105')) {
      excludedSteps = [...excludedSteps, '105'];
    }


    let lng = ucFirst(language);
    let campaignLng = ucFirst(campaignLanguage);

    let eventOptions = events.map(event => {
      return {
        label: event[`name${campaignLng}`] || event['nameFr'] || event['nameNl'] || event['nameEn'],
        id: event.id + ""
      };
    });

    let optionsChoices = options.map(option => {
      return {
        label: option[`name${lng}`] || option['nameFr'],
        id: option.id + ""
      };
    });

    let formulasOptions = formulas.map(formula => {
      return {
        label: formula[`name${lng}`] || formula['nameFr'],
        id: formula.id + ""
      };
    });

    return (
      <div className="row">
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('event')}</h1>
            <TTPSelect
              simple={true}
              notClearable={true}
              values={selectedEvent}
              placeholder={_('Select event')}
              options={eventOptions}
              onChange={this.handleEventChange.bind(this)} />
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('Formulas')}</h1>
            <TTPSelect
              values={selectedPlan}
              placeholder={_('Select formulas')}
              options={formulasOptions}
              onChange={handlePlanChange} />
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('Included status')}</h1>
            <TTPSelect
              values={includedSteps}
              placeholder={_('Select status')}
              options={GUEST_STATUS}
              onChange={handleStepsInChange}
              disabled={excludedSteps} />
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('Excluded status')}</h1>
            <TTPSelect
              values={excludedSteps}
              placeholder={_('Exclude status')}
              options={GUEST_STATUS}
              onChange={handleStepsNinChange}
              disabled={includedSteps} />
          </div>
        </div>
        <div className="columns small-6 columns-padding">
          <div className="sidebar__form-control">
            <h1 className="param__title">{_('Options')}</h1>
            <TTPSelect
              values={selectedOption}
              placeholder={_('Select options')}
              options={optionsChoices}
              onChange={handleOptionChange} />
          </div>
        </div>
      </div>
    );
  }
}
