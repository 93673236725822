import {
  convertToStringTag,
  convertDateFromUTC,
  API_DATE_FORMAT,
} from "../../services/utils";

const initialCampaignProperties = {
  id: null,
  recipientType: "MANUAL",
  recipientsApp: "COMMUNITY",
  currentSideBar: "",
  lastTestversion: "",
  manualRecipients: [],
  approved: 0,
  testComment: "",
  textModel: null,
  textModelTitle: null,
  textModelName: null,
  textModelScope: null,
  textModelLanguage: "",
  textModelTargetApp: "",
  approvalTeams: [],
  eventFilters: [],
  surveyInstance: null,
  surveyType: "INVITATION",
  allowedGroups: [],
  deniedGroups: [],
  oldCampaignGroups: [], //memo old groups
  notifyCommunityChannel: 0,
  newsLetter: 0,
  attachments: [],
  deletedAttachmentsIds: [],
  status: "DRAFT",
  language: "fr",
  pageUrl: "",
  theme: null,
  replyTo: [],
  testEmails: [],
  type: "",
  design: "",
  mutatedContent: "",
  html: "",
  object: "",
  from: "",
  fromEmailValid: false,
  fromName: "",
  isTested: 0,
  totalRecipients: 0,
  createdAt: null,
  updatedAt: null,
  sentAt: null,
  scheduledDeliveryAt: null,
  recipientListCreated: 0,
  filters: [],
  targetId: null,
  disableRecipientType: false, //disable for survey types
  mailingLists: [],
  isAutoSend: 0,
  commitmentScoreRange: { min: 0, max: 100 },
  broadcastRecipients: [],
  isRebroadcasted: 0,
  broadcastedRecipients: [],
  rebroadcastedFrom: null,
  uaRecipientType: ["MANUAL"],
  titleSelection: "",
  liteRecipients: [],
  personalizedRecipients: [],
  eliminatedRecipients: [],
  uaTotalRecipients: 0,
  articleNewsletterId: null,
  nbOfRecipients: 0,
  totalUaRecipients: null,
  numberOfNewsletter: 0,
  uaFilters: [],
  lastNumNewsletter: 0,
  introduction: "",
  autoSendApprovalNl: false,
  delegationTypes: [],
  textContent :"",
  profession: "",
  signature: "",
  avatar  : "",
};
const initialUaNewsletterProperties = {
  uaNewsletterId: null,
  isUaNewsletter: false,
  programmedDate: null,
  idApproval: null,
  approvalAction: null,
  isNlBlog: false,
  cmail: null,
};

const initialStateForEmailCampaign = {
  ...initialCampaignProperties,
  source: "",
  year: "2024",
  statut: "PAID",
  formulas: [] ,
  fetching: false,
  updating: false,
};

const mapDataToCampaign = (data) => {
  let newCampaignProperties = {};
  const campaign = data;
  for (let property in initialCampaignProperties) {
    newCampaignProperties[property] =
      campaign[property] || initialCampaignProperties[property];
  }

  newCampaignProperties["nbOfRecipients"] = parseInt(
    campaign["totalRecipients"],
    10,
  );
  newCampaignProperties["replyTo"] = convertToStringTag(
    campaign["replyTo"] || [],
  );
  newCampaignProperties["scheduledDeliveryAt"] =
    convertDateFromUTC(campaign.scheduledDeliveryAt, API_DATE_FORMAT, null) ||
    null;

  newCampaignProperties["articleNewsletterId"] =
    campaign["articleNewsletter"] || null;

  if (newCampaignProperties["design"]?.length) {
    newCampaignProperties["design"] = JSON.parse(
      newCampaignProperties["design"],
    );
  }

  if (campaign.campaignGroups && campaign.campaignGroups.length > 0) {
    let allowedGroups = [];
    let deniedGroups = [];

    campaign.campaignGroups.forEach((campaignGroup) => {
      if (campaignGroup.status == "INCLUDE") {
        allowedGroups.push(campaignGroup.group.id.toString());
      } else if (campaignGroup.status == "EXCLUDE") {
        deniedGroups.push(campaignGroup.group.id.toString());
      }
    });
    newCampaignProperties = {
      ...newCampaignProperties,
      allowedGroups,
      deniedGroups,
      oldCampaignGroups: campaign.campaignGroups,
    };
  }
  return {
    ...initialStateForEmailCampaign,
    ...newCampaignProperties,
    fetching: false,
    updating: false,
  };
};

export const currentEmailCampaignReducer = (
  state = initialStateForEmailCampaign,
  action,
) => {
  switch (action.type) {
    case "SET_CURRENT_EMAIL_CAMPAIGN_DATA": {
      return { ...state, ...action.data };
      break;
    }

    case "SET_NEWS_LETTER": {
      return { ...state, newsLetter: action.active };
      break;
    }

    case "INIT_CURRENT_EMAIL_CAMPAIGN_DATA": {
      return { ...initialStateForEmailCampaign, ...(action.data || {}) };
      break;
    }
    case "SET_TITLE_SELECTION": {
      return { ...state, titleSelection: action.titleSelection };
      break;
    }
    case "SET_EVENTS_FILTERS": {
      let { data } = action;
      let exists = false;

      let newEventFilters = [];
      if (state.eventFilters instanceof Array) {
        state.eventFilters.forEach((filter) => {
          if (
            filter.property != data.property ||
            filter.operator != data.operator
          ) {
            newEventFilters.push(filter);
          } else if (data.value) {
            exists = true;
            newEventFilters.push(data);
          }
        });
      }

      if (!exists && data.value) {
        newEventFilters.push(data);
      }

      return { ...state, eventFilters: newEventFilters };
      break;
    }

    case "FETCH_CURRENT_EMAIL_CAMPAIGN_PENDING":
      return {
        ...initialStateForEmailCampaign,
        fetching: true,
      };
    case "FETCH_CURRENT_EMAIL_CAMPAIGN_FULFILLED":
      const { data } = action.payload.data;
      if (!data || data.length != 1) {
        return initialStateForEmailCampaign;
      }
      return mapDataToCampaign(data[0]);
    case "FETCH_CURRENT_EMAIL_CAMPAIGN_REJECTED":
      return {
        ...initialStateForEmailCampaign,
        fetching: false,
      };
    case "SAVE_CURRENT_EMAIL_CAMPAIGN_PENDING":
      return {
        ...state,
        updating: true,
      };
    case "SAVE_CURRENT_EMAIL_CAMPAIGN_FULFILLED":
      return {
        ...mapDataToCampaign(action.payload),
        fromEmailValid: true,
      };
    case "SAVE_CURRENT_EMAIL_CAMPAIGN_REJECTED":
      return {
        ...state,
        updating: false,
      };
    case "SAVE_RECIPIENT_CRITERIA_PENDING":
      return {
        ...state,
        updating: true,
      };
    case "SAVE_RECIPIENT_CRITERIA_FULFILLED":
      return {
        ...state,
        updating: false,
      };
    case "SAVE_RECIPIENT_CRITERIA_REJECTED":
      return {
        ...state,
        updating: false,
      };
    case "SET_CURRENT_CAMPAIGN_THEME":
      const theme = action.theme;
      return {
        ...state,
        html: state.type != "PLAIN_TEXT" ? theme?.content : state.html,
        mutatedContent: state.type == "PLAIN_TEXT" ? theme?.content : "",
        design: theme?.design || "",
        theme: theme,
      };
    case "FETCH_LAST_SENT_CAMPAIGN_PENDING": {
      return { ...state, fetched: false, fetching: true };
      break;
    }
    case "FETCH_LAST_SENT_CAMPAIGN_FULFILLED": {
      const { data } = action.payload;
      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        lastNumNewsletter: data?.numberOfNewsletter,
      };
      break;
    }
    case "FETCH_LAST_SENT_CAMPAIGN_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
      };
      break;
    }
    case "FETCH_COUNTS_RECIPIENTS_BY_TYPES_PENDING": {
      return { ...state, fetched: false, fetching: true };
      break;
    }
    case "FETCH_COUNTS_RECIPIENTS_BY_TYPES_FULFILLED": {
      const { data } = action.payload;

      return {
        ...state,
        fetching: false,
        fetched: true,
        error: null,
        // totalUaRecipients: totalUaRecipients,
      };
      break;
    }
    case "FETCH_COUNTS_RECIPIENTS_BY_TYPES_REJECTED": {
      let error = action.payload;
      switch (error && error.response && error.response.status) {
        case 404:
          error = {
            title: error.response.data.title,
            code: 404,
          };
          break;
        default:
      }
      return {
        ...state,
        fetching: false,
        error,
      };
      break;
    }
    default:
      return state;
  }
};

const initialStateForSmsCampaign = {
  recipientType: "MANUAL",
  language: "fr",
  currentSideBar: "",
  manualSmsRecipients: [],
  smsTextModel: null,
  smsTextModelTitle: null,
  allowedGroups: [],
  deniedGroups: [],
  testPhoneNumbers: [],
  campaignName: "",
  smsMessage: "",
};

export const currentSmsCampaignReducer = (
  state = initialStateForSmsCampaign,
  action,
) => {
  switch (action.type) {
    case "SET_CURRENT_SMS_CAMPAIGN_DATA": {
      return { ...state, ...action.data };
      break;
    }
    case "INIT_CURRENT_SMS_CAMPAIGN_DATA": {
      return action.data
        ? { ...initialStateForSmsCampaign, ...action.data }
        : initialStateForSmsCampaign;
      break;
    }
    default:
      return state;
  }
};
export const uaNewsletterStateReducer = (
  state = initialUaNewsletterProperties,
  action,
) => {
  switch (action.type) {
    case "SET_UA_NEWSLETTER_ID": {
      return { ...state, uaNewsletterId: action.uaNewsletterId };
      break;
    }
    case "SET_IS_UA_NEWSLETTER": {
      return { ...state, isUaNewsletter: action.isUaNewsletter };
      break;
    }
    case "SET_PROGRAMMED_DATE": {
      return { ...state, programmedDate: action.programmedDate };
      break;
    }
    case "SET_UA_ID_APPROVAL": {
      return { ...state, idApproval: action.idApproval };
      break;
    }
    case "SET_UA_APPROVAL_ACTION": {
      return { ...state, approvalAction: action.approvalAction };
      break;
    }
    case "SET_IS_NL_BLOG": {
      return { ...state, isNlBlog: action.isNlBlog };
      break;
    }
    case "SET_UA_CMAIL": {
      return { ...state, cmail: action.cmail };
      break;
    }
    default:
      return state;
  }
};
