import * as actions from "actions";
import { getSubscriptionDetails, updateSubscription } from "TamtamAPI";
import {updateSubscriptionTT} from "../../api/TamtamAPI/subscriptionUA";

export const fetchSubscriptionDetails = (cmail) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(
      actions.fetchSubscriptionDetails(getSubscriptionDetails(token, cmail)),
    );
  };
};

export const toggleSubscription = (data) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(
      actions.toggleSubscription(updateSubscription(token, data)),
    );
  };
};
export const toggleSubscriptionTT = (data) => {
  return (dispatch, getState) => {
    const { token } = getState().auth;

    return dispatch(
      actions.toggleSubscription(updateSubscriptionTT(token, data)),
    );
  };
};
