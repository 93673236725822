export const initSubscriptionDetails = (resp) => {
  return {
    type: "INIT_SUBSCRIPTION_DETAILS",
    payload: resp,
  };
};

export const fetchSubscriptionDetails = (resp) => {
  return {
    type: "FETCH_SUBSCRIPTION_DETAILS",
    payload: resp,
  };
};

export const toggleSubscription = (resp) => {
  return {
    type: "TOGGLE_SUBSCRIPTION",
    payload: resp,
  };
};
