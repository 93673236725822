import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "i18n";
import {
  addFeedToNewsletter,
  initTemplatesList,
  setCurrentNewsletterTemplate,
  setNewsletterBlocks,
} from "../../actions/actions/theme";
import { fetchTemplate } from "../../actions/thunks/theme";
import {fetchUser} from "../../actions/thunks/user";
import { CSSTransition } from "react-transition-group";
import { ucFirst } from "../../services/common";
import { initArticles } from "../../actions/actions/article";
import { decomposeNewsletter } from "../../services/block/newsletter";
import Loader from "react-loaders";
import { replaceEmailContent ,getUserNameForAvatar} from "../../services/utils";
import {
  initCurrentEmailCampaignData,
  setCurrentEmailCampaignData,
} from "../../actions/actions/currentCampaign";
import { store } from "../../index.js";
const mapStateToProps = (state) => ({
  templates: state.themes.list.items,
  currentTemplate: state.themes.newsletter.currentTemplate,
  blocks: state.themes.newsletter.blocks,
  html: state.currentCampaign.email.html,
  currentClient: state.auth.currentClient,
  object: state.currentCampaign.email.object,
  textContent: state.currentCampaign.email.textContent,
  profession: state.currentCampaign.email.profession,
  fromName: state.currentCampaign.email.fromName,
  avatar : state.currentCampaign.email.avatar,
  mutatedContent: state.currentCampaign.email.mutatedContent,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTemplate: (id) => dispatch(fetchTemplate(id)),
  initTemplates: () => dispatch(initTemplatesList()),
  setCurrentNewsletterTemplate: (data) =>
    dispatch(setCurrentNewsletterTemplate(data)),
  setNewsletterBlocks: (data) => dispatch(setNewsletterBlocks(data)),
  initArticles: () => dispatch(initArticles()),
  addFeedToNewsletter: (article) => dispatch(addFeedToNewsletter(article)),
  initCurrentCampaign: (data) => dispatch(initCurrentEmailCampaignData(data)),
  setCurrentCampaign: (data) => dispatch(setCurrentEmailCampaignData(data)),
  fetchUser: (id) => dispatch(fetchUser(id)),
  
});

@connect(mapStateToProps, mapDispatchToProps)
export default class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      device: DESKTOP,
      currentObject: this.props.object,
      template: null,
    };
    this.handleDeviceChange = (device) => this.setState({ device });
  }

  componentDidMount() {
    this.updateNewsletter();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isPeriodChanged, currentPage,avatar} = this.props;
    if (
      (prevProps.isPeriodChanged !== isPeriodChanged && isPeriodChanged) ||
      (prevProps.currentPage !== currentPage &&
        currentPage === "RICH_TEXT_PAGE")
    ) {
      this.updateNewsletter();
    }
  }

  updateNewsletter = () => {
    const {
      setCurrentNewsletterTemplate,
      setNewsletterBlocks,
      initArticles,
      blocks,
      newsletterId,
      currentClient,
      currentPage,
      setCurrentCampaign,
    } = this.props;
    let plan = "";
    let isLogoUaVisible = true;

    if (
      currentClient &&
      currentClient["newsletterPreferences"] &&
      currentClient["newsletterPreferences"]["apps"]?.length > 0
    ) {
      let filteredApps = currentClient["newsletterPreferences"]["apps"].filter(
        (app) => app.type === "BLOG_NL_CREATE",
      );
      plan = filteredApps[0].config;
      isLogoUaVisible = filteredApps[0]?.isLogoUaVisible ?? true;
    }

    if (newsletterId == null || plan === "PERSONALIZED") {
      this.props.initTemplates();
      let idTheme = null;

      if (currentPage === "SEND_PAGE") {
        if (currentClient.id === 4) {
          idTheme = 11224; // Deg template (8 articles)
        } else {
          if (plan === "LITE") {
            idTheme = 10644; // Lite template (2 articles)
          } else if (plan === "STANDARD") {
            idTheme = 11693; // Standard template (4 articles)
          } else {
            if (isLogoUaVisible) {
              idTheme = 10267; // Personalized template (8 articles) with Ua logo
            } else {
              idTheme = 11762; // Personalized template (8 articles) without Ua logo
            }
          }
        }
      } else if (currentPage === "RICH_TEXT_PAGE") {
        if (plan === "LITE" || plan === "STANDARD" ) {
          idTheme = 12176;  // Lite or Standard theme for RICH_TEXT_PAGE
        } 
        else {
          if (isLogoUaVisible) {
            idTheme = 12176; // Personalized theme for RICH_TEXT_PAGE with Ua logo
          } else {
            idTheme = 12585; // Personalized theme for RICH_TEXT_PAGE without Ua logo
          }
        }
        setCurrentCampaign({ theme: {id:idTheme} });
      }

      if (idTheme) {
        this.props.fetchTemplate(idTheme).then((res) => {
          const fetchedTemplate = res.value.data.data;
          this.setState({ template: fetchedTemplate });
          setCurrentNewsletterTemplate(fetchedTemplate);

          let feeds = blocks.reduce((acc, { feeds }) => {
            return [...acc, ...feeds];
          }, []);

          decomposeNewsletter(fetchedTemplate, (blocks) => {
            let i = 0;
            blocks =
              feeds && feeds.length > 0
                ? blocks.map((block) => {
                    if (
                      block.type !== "UNREFERENCED_BLOCK" &&
                      feeds.length > i
                    ) {
                      block.feeds = feeds.slice(i, (i += block.column));
                      return block;
                    }
                    return block;
                  })
                : blocks;
            setNewsletterBlocks(blocks);
          });
        });
        initArticles();
      }
    }
  };

  close = () => {
    const sidebar = document.getElementById("settings__sidebar");
    if (sidebar) {
      sidebar.classList.remove("display");
      const mask = document.getElementsByClassName("mask").item(0);
      mask.classList.add("hide");
    }
  };

  getTemplateHtml() {
    const { object, html, textContent, setCurrentCampaign, fromName, profession, avatar } = this.props;
    const { template } = this.state;
    const fiduciaryLogo = store.getState().auth.currentClient.avatarUrl;

    let content = html;
    if (template && template.content) {
        const imageUrl = avatar !== undefined
            ? `<img src="${avatar}" alt="Photo de profil" style="width: 50px; height: 50px; border-radius: 10px;">`
            : `<div class="image-placeholder" style="width: 50px; height: 50px; border-radius: 10px; border: 2px solid var(--Gris-4, #F1F2F4); background: lightgray 50% / cover no-repeat; text-align: center; align-content: center; font-weight: bold; font-size: 24px; color: white;">${getUserNameForAvatar(fromName)}</div>`;

        content = replaceEmailContent(
            {
                body: textContent,
                subject: object,
                fullName: fromName,
                profession: profession,
                imageUrl: imageUrl,
                fiduciaryLogoUrl: fiduciaryLogo,
            },
            template.content,
        );

        setCurrentCampaign({ mutatedContent: content });
        setCurrentCampaign({ html: textContent });
    }

    return content;
}


  render() {
    const { device } = this.state;
    const { html, showPreview, currentPage } = this.props;
    let iframeProps = {};
    iframeProps.srcDoc = html;
    return (
      <div id="settings__sidebar" className="preview-sidebar">
        <div
          className="columns small-5 preview-sidebar__close"
          onClick={this.close}
        >
          <span className="preview-sidebar__close-title">
            {_("fermer l'aperçu")}
          </span>
          <span className="preview-sidebar__close-icon icomoon icon-tt-close" />
        </div>

        {!showPreview && (
          <div className="drag-drop-editor__overlay preview-sidebar__loader">
            <Loader type="line-spin-fade-loader" />
          </div>
        )}

        <CSSTransition
          in={showPreview}
          timeout={500}
          classNames="campaign-preview"
          unmountOnExit
        >
          <div className={`preview-sidebar__content medium-11 m-l-s`}>
            <div className="campaign-preview__device">
              {[DESKTOP, MOBILE].map((value) => (
                <span
                  key={value}
                  className={`${value === device ? "active" : ""}`}
                  onClick={() => this.handleDeviceChange(value)}
                >
                  {ucFirst(_(value.toLowerCase()))}
                </span>
              ))}
            </div>
            <div className={`campaign-preview__content`}>
              <iframe
                className={`${MOBILE === device ? "mobile" : ""}`}
                ref="iframe"
                scrolling="auto"
                allowFullScreen=""
                {...iframeProps}
                srcDoc={
                  currentPage === "RICH_TEXT_PAGE"
                    ? this.getTemplateHtml()
                    : html
                }
              />
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  }
}

const DESKTOP = "DESKTOP";
const MOBILE = "MOBILE";
